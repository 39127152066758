import React, { useState, useEffect, useRef } from 'react';
import '../Title1doss/Title1.css';

const Title1: React.FC = () => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const titleRef = useRef<HTMLHeadingElement>(null);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const titleElement = titleRef.current;
      if (titleElement) {
        const rect = titleElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const titleTopOffset = rect.top;
        const titleBottomOffset = rect.bottom;

        let progress = 0;
        if (titleTopOffset >= 0 && titleBottomOffset <= windowHeight) {
          progress = (windowHeight - titleTopOffset) / windowHeight;
          if (scrollDirection === 'down') {
            setAnimationProgress(progress);
          } else {
            setAnimationProgress(1 - progress);
          }
        }
      }
    };

    const updateScrollDirection = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollY.current = currentScrollY;
    };

    const onScroll = () => {
      updateScrollDirection();
      handleScroll();
    };

    const throttledOnScroll = () => {
      requestAnimationFrame(onScroll);
    };

    window.addEventListener('scroll', throttledOnScroll);
    return () => {
      window.removeEventListener('scroll', throttledOnScroll);
    };
  }, [scrollDirection]);

  return (
    <div className="title-container">
      {/* Design qui monte */}
      <h2 ref={titleRef} className="design-title" style={{ transform: `translateY(-${animationProgress * 50}%)` }}>COMMUNICATION</h2>
      {/* Design qui reste en place */}
      <h2 className="design-title">COMMUNICATION</h2>
      {/* Design qui descend */}
      <h2 className="design-title" style={{ transform: `translateY(${animationProgress * 50}%)` }}>COMMUNICATION</h2>
    </div>
  );
}

export default Title1;
