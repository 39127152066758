import React, { useState, ChangeEvent } from 'react';
import './Listeservices.css';

const NewsletterButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setEmail('');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
<div className="newsletter-button text-arial-bold">
  <h2>Newsletter</h2>
  <button className="newsletter-btn text-arial-bold" disabled>Coming Soon</button>


      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-btn" onClick={closeModal}>&times;</button>
            <h2>Inscription à la Newsletter</h2>
            <form>
              <input 
                type="email" 
                placeholder="Votre email" 
                value={email}
                onChange={handleInputChange}
                required // Champ obligatoire
              />
              <button type="submit" className="subscribe-btn">S'inscrire</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsletterButton;
