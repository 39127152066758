import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Barnav from './ComponentsBarnav/Barnav';
import Design from './Listeservices/Design';
import Nousrejoindre from './Listeservices/Nousrejoindre';
import Communication from './Listeservices/Communication';
import Footer from './Footer/Footer';
import Team from './Screens/Team/Team';
import Contact from './Screens/Contact/Contact';
import Title1 from './ComponentsTitle/Title1doss/Title1'; 
import PresentationDesign from './PresentationServices/Presentationdesign';
import Title2 from './ComponentsTitle/Title2doss/Title2'; 
import Title3 from './ComponentsTitle/Title3doss/Title3'; 

import PresentationCommunication from './PresentationServices/Presentationcommunication';
import { Link } from 'react-scroll'; // Importer Link depuis react-scroll
import ContactForm from './Contact/ContactForm';
import './App.css';

const Home: React.FC = () => {
  return (
    <>
    <h3 className='h3'>Site under construction</h3>
      <section className='pc'>
        <div className="container">
          <div className="column1 design-services"> 
            <Design />
          </div>
          <div className="column2 nous-rejoindre">
            <Nousrejoindre />
          </div>
          <div className="column3 communication-services">
            <Communication />
          </div>
        </div>
      </section>

      <section>
        <Title1 />
      </section>

      <section>
        <div className="presentationdesign">
          <PresentationDesign />
        </div>
      </section>

      <section>
        <Title2 />
      </section>

      <section>
        <div className="presentationdesign">
          <PresentationCommunication />
        </div>
      </section>

      <section>
        <Title3 />
      </section>

      <section>
      <h1>Contact Us</h1>
      <ContactForm />
    </section>

      <section>
        <Footer />
      </section>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Barnav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
