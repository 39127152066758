import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      to_name: 'Votre Nom', // Remplacez par le nom du destinataire
      from_email: email,
      message: message,
    };

    console.log('Envoi des paramètres du template:', templateParams);

    // Remplacez 'YOUR_PUBLIC_KEY' par votre clé publique EmailJS
    emailjs.send('service_h96pjph', 'template_o2t82mw', templateParams, '26884rtQ16L2BTKwG')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccessMessage('Message envoyé avec succès !');
        setErrorMessage('');
        setName('');
        setEmail('');
        setMessage('');
      }, (error) => {
        console.error('FAILED...', error);
        setErrorMessage('Échec de l\'envoi du message. Veuillez réessayer plus tard.');
        setSuccessMessage('');
      });
  };

  return (
    <div className="contact-form-container">
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nom</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ padding: '10px' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: '10px' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            style={{ padding: '10px' }}
          ></textarea>
        </div>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default ContactForm;
