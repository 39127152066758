import React from 'react';
import './Presentationdesign.css';

const PresentationDesign: React.FC = () => {
  return (
    <div>
      {/* Module 1 */}
      <div className="presentation-container top-left">
        <div className="text-container">
          <p>The design of your logo is a key step. It's not just an image, it's the first step towards a visual identity that must be consistent and powerful.</p>
        </div>
      </div>

      {/* Module 2 */}
      <div className="presentation-container bottom-right">
        <div className="text-container">
          <p>We also create your website, a modern and intuitive platform that reflects your values and engages your clients. A well-designed website is essential for attracting and retaining the attention of your visitors.</p>
        </div>
      </div>

      {/* Module 3 */}
      <div className="presentation-container top-left">
        <div className="text-container">
          <p>To enhance this online presence, we create animations and motion designs, bringing your ideas to life in a dynamic and captivating way. An animated visual identity is essential for standing out and making a lasting impression.</p>
        </div>
      </div>
    </div>
  );
}

export default PresentationDesign;
