import React, { useRef, useEffect } from 'react';
import './Barnav.css';

import logo from '../img/Big_Logo-trsp.png';



const Barnav: React.FC = () => {
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const backgroundCanvas = backgroundCanvasRef.current;
    if (!backgroundCanvas) return;

    const backgroundCtx = backgroundCanvas.getContext('2d');
    if (!backgroundCtx) return;

    const drawNoise = (width: number, height: number) => {
      backgroundCtx.clearRect(0, 0, width, height);
      backgroundCtx.fillStyle = "#FFA500";
      backgroundCtx.fillRect(0, 0, width, height);
      const noiseDensity = 0.3;
      const noiseIntensity = 100;
      for (let x = 0; x < width; x += 1) {
        for (let y = 0; y < height; y += 1) {
          if (Math.random() < noiseDensity) {
            const noiseValue = Math.random() * noiseIntensity - (noiseIntensity / 2);
            backgroundCtx.fillStyle = `rgba(0, 0, 0, ${noiseValue / 255})`;
            backgroundCtx.fillRect(x, y, 1, 1);
          }
        }
      }
    };

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      backgroundCanvas.width = width;
      backgroundCanvas.height = height;
      drawNoise(width, height); // Redessiner le bruit après le redimensionnement
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="barnav-container">
      <nav className="barnav">
        <canvas className="background-canvas" ref={backgroundCanvasRef} />

        <img src={logo} alt="Logo" className="logo" />
      </nav>
    </div>
  );
};

export default Barnav;
