import React, { useEffect, useRef } from 'react';
import Title4 from '../../ComponentsTitle/Title4doss/Title4';
import Footer from '../../Footer/Footer';
import './Team.css'; // Importer le CSS

const Team: React.FC = () => {
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const backgroundCanvas = backgroundCanvasRef.current;
    if (!backgroundCanvas) return;

    const backgroundCtx = backgroundCanvas.getContext('2d');
    if (!backgroundCtx) return;

    const drawNoise = (width: number, height: number) => {
      backgroundCtx.clearRect(0, 0, width, height);
      backgroundCtx.fillStyle = "#FFA500";
      backgroundCtx.fillRect(0, 0, width, height);
      const noiseDensity = 0.3;
      const noiseIntensity = 100;
      for (let x = 0; x < width; x += 1) {
        for (let y = 0; y < height; y += 1) {
          if (Math.random() < noiseDensity) {
            const noiseValue = Math.random() * noiseIntensity - (noiseIntensity / 2);
            backgroundCtx.fillStyle = `rgba(0, 0, 0, ${noiseValue / 255})`;
            backgroundCtx.fillRect(x, y, 1, 1);
          }
        }
      }
    };

    const animateBackground = () => {
      const width = backgroundCanvas.width;
      const height = backgroundCanvas.height;
      drawNoise(width, height);
      requestAnimationFrame(animateBackground);
    };

    animateBackground();

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      backgroundCanvas.width = width;
      backgroundCanvas.height = height;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='bodyteam'>
      <Title4 />
      <canvas className="background-canvas" ref={backgroundCanvasRef} />
      <div className="team-member-card">
        <div className="team-member-image"></div>
        <h2>Nom de la personne</h2>
        <p>Poste de la personne</p>
      </div>
      <div className="team-member-profile">
        <h3>A propos</h3>
        <p>ici je vais expliquer un peu ma vie en bref et ma mission</p>
      </div>
      <Footer />
    </div>
  );
}

export default Team;
