import React, { useState, useEffect, useRef } from 'react';


const Title1: React.FC = () => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const titleElement = titleRef.current;
      if (titleElement) {
        const rect = titleElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const titleTopOffset = rect.top;
        const titleBottomOffset = rect.bottom;

        let progress = 0;
        if (titleTopOffset >= 0 && titleBottomOffset <= windowHeight) {
          progress = (windowHeight - titleTopOffset) / windowHeight;
          if (scrollDirection === 'down') {
            setAnimationProgress(progress);
          } else {
            setAnimationProgress(1 - progress);
          }
        }
      }
    };

    const handleScrollDirection = () => {
      const lastScrollY = window.scrollY;
      window.addEventListener('scroll', () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
      });
    };

    handleScrollDirection();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection]);

  return (
    <div className="title-container">
      {/* Design qui monte */}
      <h2 ref={titleRef} className="design-title" style={{ transform: `translateY(-${animationProgress * 50}%)` }}>CONTACT</h2>
      {/* Design qui reste en place */}
      <h2 className="design-title">CONTACT</h2>
      {/* Design qui descend */}
      <h2 className="design-title" style={{ transform: `translateY(${animationProgress * 50}%)` }}>CONTACT</h2>
    </div>
  );
}

export default Title1;
