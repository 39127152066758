import React, { useEffect, useRef } from 'react';
import Title5 from '../../ComponentsTitle/Title5doss/Title5';
import Footer from '../../Footer/Footer';
import './Contact.css'; // Importer le CSS

const Contact: React.FC = () => {
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const backgroundCanvas = backgroundCanvasRef.current;
    if (!backgroundCanvas) return;

    const backgroundCtx = backgroundCanvas.getContext('2d');
    if (!backgroundCtx) return;

    const drawNoise = (width: number, height: number) => {
      backgroundCtx.clearRect(0, 0, width, height);
      backgroundCtx.fillStyle = "#FFA500";
      backgroundCtx.fillRect(0, 0, width, height);
      const noiseDensity = 0.3;
      const noiseIntensity = 100;
      for (let x = 0; x < width; x += 1) {
        for (let y = 0; y < height; y += 1) {
          if (Math.random() < noiseDensity) {
            const noiseValue = Math.random() * noiseIntensity - (noiseIntensity / 2);
            backgroundCtx.fillStyle = `rgba(0, 0, 0, ${noiseValue / 255})`;
            backgroundCtx.fillRect(x, y, 1, 1);
          }
        }
      }
    };

    const animateBackground = () => {
      const width = backgroundCanvas.width;
      const height = backgroundCanvas.height;
      drawNoise(width, height);
      requestAnimationFrame(animateBackground);
    };

    animateBackground();

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      backgroundCanvas.width = width;
      backgroundCanvas.height = height;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='bodycontact'>
      <Title5 />
      <div className="contact-form-container">
        <canvas className="background-canvas" ref={backgroundCanvasRef} style={{ position: 'absolute', zIndex: -1 }} />
        <form className="contact-form">
          {/* Votre formulaire ici */}
          <input type="text" placeholder="Nom" />
          <input type="email" placeholder="Email" />
          <textarea placeholder="Message"></textarea>
          <button type="submit">Envoyer</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
