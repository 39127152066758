import React from 'react';
import './Listeservices.css';
import logoalternatif from '../img/Logo-PNG-400x400.png'; // Importez le logo depuis le chemin spécifié

const CommunicationServices: React.FC = () => {
  return (
    <div className="communication-services text-arial-bold">
      <h2>COMMUNICATION</h2>
      <ul>
        <li><a href="#">Brand Strategy</a></li>
        <li><a href="#">Public Relations</a></li>
        <li><a href="#">Content Marketing</a></li>
        <li><a href="#">Social Media Management</a></li>
        <li><a href="#">Digital Advertising</a></li>
        <li><a href="#">Email Marketing</a></li>
      </ul>
      <img src={logoalternatif} alt="Logo" className="logoalternatif" />
    </div>
  );
}

export default CommunicationServices;
