import React from 'react';
import './Presentationdesign.css';

function removeExtraSpaces(text: string) {
  return text.replace(/\s+/g, ' ').trim();
}

const PresentationDesign: React.FC = () => {
  return (
    <div>
      {/* Module 4 */}
      <div className="presentation-container bottom-right">
        <div className="text-container">
          <p>{removeExtraSpaces("Our marketing supports complement this identity. Brochures, flyers, and business cards are designed to effectively promote your business. Every element of your communication will be thoughtfully crafted to be impactful and memorable.")}</p>
        </div>
      </div>

      {/* Module 5 */}
      <div className="presentation-container top-left">
        <div className="text-container">
          <p>{removeExtraSpaces("We accompany you in your communication strategy. Whether it's through social media, digital advertising campaigns, or email marketing, we help you reach your audience and establish an authentic connection with your clients.")}</p>
        </div>
      </div>

      {/* Module 6 */}
      <div className="presentation-container bottom-right">
        <div className="text-container">
          <p>{removeExtraSpaces("At SixThem, we don't just create designs, we build brands. We provide you with all the necessary tools for your business to stand out, both online and offline. We're here to help you tell your story in the most impactful way possible.")}</p>
        </div>
      </div>
      <div>
  <p className="fin">
    {removeExtraSpaces("Your success is our mission. Together, let's bring your vision to life.")}
  </p>
</div>


    </div>
  );
}

export default PresentationDesign;
