import React, { useState, useEffect, useRef } from 'react';
import './Title1.css';

const Title1: React.FC = () => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const lastScrollY = useRef(0);
  const lastScrollDirection = useRef<'up' | 'down'>('down');
  const scrollThrottleTimeout = useRef<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const titleElement = titleRef.current;
      if (titleElement) {
        const rect = titleElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const titleTopOffset = rect.top;
        const titleBottomOffset = rect.bottom;

        let progress = 0;
        if (titleTopOffset >= 0 && titleBottomOffset <= windowHeight) {
          progress = (windowHeight - titleTopOffset) / windowHeight;
          setAnimationProgress(progress);
        }
      }
    };

    const throttledHandleScroll = () => {
      if (!scrollThrottleTimeout.current) {
        scrollThrottleTimeout.current = window.setTimeout(() => {
          scrollThrottleTimeout.current = null;
          handleScroll();
        }, 100); // Délai de 100 ms entre les appels de la fonction de défilement
      }
    };

    const throttledOnScroll = () => {
      requestAnimationFrame(throttledHandleScroll);
    };

    window.addEventListener('scroll', throttledOnScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', throttledOnScroll);
      if (scrollThrottleTimeout.current) {
        clearTimeout(scrollThrottleTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScrollDirection = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current) {
        lastScrollDirection.current = 'down';
      } else {
        lastScrollDirection.current = 'up';
      }
      lastScrollY.current = currentScrollY;

      // Réinitialiser l'animation lorsque la position de défilement atteint le sommet de la page
      if (currentScrollY === 0) {
        setAnimationProgress(0);
      }
    };

    window.addEventListener('scroll', handleScrollDirection, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollDirection);
    };
  }, []);


    return (
      <div className="title-container">
        {/* Design qui monte */}
        <h2 ref={titleRef} className={`design-title ${animationProgress < 1 ? 'duplicate' : ''}`} style={{ transform: `translateY(-${animationProgress * 50}%)` }}>DESIGN</h2>
        {/* Design qui reste en place */}
        <h2 className="design-title">DESIGN</h2>
        {/* Design qui descend */}
        <h2 className={`design-title ${animationProgress > 0 ? 'duplicate' : ''}`} style={{ transform: `translateY(${animationProgress * 50}%)` }}>DESIGN</h2>
      </div>
    );
    
}

export default Title1;
