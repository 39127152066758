import React from 'react';
import './Listeservices.css';

const DesignServices: React.FC = () => {
  return (
    <div className="design-services text-arial-bold align-right">
      <h2>Design</h2>
      <ul>
        <li><a href="#">Website Design</a></li>
        <li><a href="#">Logo Design</a></li>
        <li><a href="#">Visual Identity Design</a></li>
        <li><a href="#">User Interface (UI) Design</a></li>
        <li><a href="#">Marketing Collateral Design</a></li>
        <li><a href="#">Graphic Design</a></li>
      </ul>
    </div>
  );
}

export default DesignServices;
